import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@agdir/domain';
import { CUSTOMER_PATHS } from '@/constants';

export const IsAuthenticated: CanActivateFn = async () => {
	const auth = inject(AuthService);
	const router = inject(Router);
	try {
		const s = await auth.getCurrentCustomer();
		return s ? true : router.navigateByUrl(CUSTOMER_PATHS.AUTH_SIGNIN);
	} catch {
		return router.navigateByUrl(CUSTOMER_PATHS.AUTH_SIGNIN);
	}
};
